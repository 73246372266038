<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <div class="text-center">
            <v-dialog
                    v-model="dialog"
                    persistent
                    width="50"
            >
                <img src='../../assets/loader_spinner.gif' style="width: 50px; ">

            </v-dialog>
        </div>
        <v-row>
            <v-col>

                <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            실시간 예약
                        </h1>
                    </v-col>
                </v-row>
                <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                    <v-col
                            class="text-center"
                            cols="12"
                    >
                        <h1 class="text-h4 font-weight-bold white--text">
                            실시간 예약
                        </h1>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row class="px-3">

                        <v-col cols="12">
                            <table class="resvFormTable">
                                <colgroup>
                                    <col class="hidden-md-and-down" style="width:20%"/>
                                    <col style="width:60%"/>
                                    <col class="hidden-md-and-down" style="width:20%"/>
                                </colgroup>
                                <tr>
                                    <td class="hidden-md-and-down"></td>
                                    <td style="display: flex">
                                        <input type="text" id="dateRange" name="dateRange" v-model="dateRangeText" style="text-align: center;"
                                               readonly />
                                    </td>
                                    <td class="hidden-md-and-down"></td>
                                </tr>
                            </table>

                        </v-col>
                        <v-col cols="12" md="12" v-if="isSearch">
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul class="resvDateRangeBox">
                                        <li class="">
                                            <div>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </template>

                                            </div>
                                        </li>
                                        <li class="resvDateRangeBox_dateRage">
                                            {{dates.dateRange}}
                                        </li>
                                        <li class="">
                                            <div>
                                                <template v-if="dates.startDate == '-'">
                                                    -
                                                </template>
                                                <template v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </template>

                                            </div>
                                        </li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <table class="resvDateRangeTable">
                                        <tr>
                                            <td>
                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.startDate}}({{dates.startDow}})
                                                </div>
                                            </td>
                                            <td class="resvDateRangeTable_dateRage">
                                                {{dates.dateRange}}
                                            </td>
                                            <td>
                                                <div v-if="dates.startDate == '-'">
                                                    -
                                                </div>
                                                <div v-else>
                                                    {{dates.endDate}}({{dates.endDow}})
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul class="roundBtn-blue calc-5 searchGubun">
                                        <li><a @click="clickSearchGubun('R')" class="searchGubun_R">객실</a></li>
                                        <li><a @click="clickSearchGubun('A')" class="searchGubun_A">캠핑장A</a></li>
                                        <li><a @click="clickSearchGubun('B')" class="searchGubun_B">캠핑장B</a></li>
                                        <li><a @click="clickSearchGubun('C')" class="searchGubun_C">캠핑장C</a></li>
                                        <li><a @click="clickSearchGubun('D')" class="searchGubun_D">캠핑장D</a></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul class="roundBtn-blue calc-3 searchGubun">
                                        <li><a @click="clickSearchGubun('R')" class="searchGubun_R">객실</a></li>
                                        <li><a @click="clickSearchGubun('A')" class="searchGubun_A">캠핑장A</a></li>
                                        <li><a @click="clickSearchGubun('B')" class="searchGubun_B">캠핑장B</a></li>
                                    </ul>
                                    <ul class="roundBtn-blue calc-3 searchGubun">
                                        <li><a @click="clickSearchGubun('C')" class="searchGubun_C">캠핑장C</a></li>
                                        <li><a @click="clickSearchGubun('D')" class="searchGubun_D">캠핑장D</a></li>
                                    </ul>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="reservation-select-title">
                                    예약가능 리스트
                                </v-col>

                                <table class="resvTable hidden-sm-and-down">
                                    <colgroup>
                                        <col style="width:10%"/>
                                        <col style="width:20%"/>
                                        <col style="width:25%"/>
                                        <col style="width:20%"/>
                                        <col style="width:10%"/>
                                        <col style="width:15%"/>
                                    </colgroup>
                                    <tr>
                                        <th></th>
                                        <th>객실명</th>
                                        <th>형태</th>
                                        <th>기준/최대</th>
                                        <th>예약인원</th>
                                        <th class="num-right">이용요금</th>
                                    </tr>
                                    <tr
                                            v-for="(item,i) in resvSelectList"
                                            :key="i">
                                        <td class="mx-auto">
                                            <template v-if="!item.isSelect">
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3">
                                                </v-checkbox>
                                            </template>
                                            <template v-else>
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3"
                                                        disabled>
                                                </v-checkbox>
                                            </template>

                                        </td>
                                        <td>{{item.roomNm}}</td>
                                        <td>{{item.roomForm}}</td>
                                        <td>{{item.stdNop}}명/{{item.maxNop}}명</td>
                                        <td>
                                            <template v-if="dateRange > 0">
                                                <select v-model="item.resvNop" @change="selectChange($event, item)">
                                                    <option v-for="(n, i) in item.maxNopNum" :value="n">{{n}}</option>
                                                </select>
                                            </template>
                                            <template v-else>
                                                <select disabled>
                                                    <option value="0">0</option>
                                                </select>
                                            </template>
                                        </td>
                                        <td class="num-right">
                                            <template v-if="item.isSale=='true'">
                                                <span style="text-decoration: line-through; color:red;">{{item.oldPrice | comma}}원</span>
                                                <br>
                                            </template>
                                            <span style="color:#1976d2; font-weight: bold;">{{item.totalPrice | comma}}원</span>
                                        </td>
                                    </tr>
                                </table>
                                <table class="resvTable hidden-md-and-up" style="border-top: 1px black solid;">
                                    <colgroup>
                                        <col style="width:10%"/>
                                        <col style="width:30%"/>
                                        <col style="width:25%"/>
                                        <col style="width:35%"/>
                                    </colgroup>
                                    <tr
                                            v-for="(item,i) in resvSelectList"
                                            :key="i">
                                        <td class="mx-auto">
                                            <template v-if="!item.isSelect">
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3">
                                                </v-checkbox>
                                            </template>
                                            <template v-else>
                                                <v-checkbox
                                                        v-model="item.isCheck"
                                                        color="primary accent-3"
                                                        class="shrink ml-3"
                                                        disabled>
                                                </v-checkbox>
                                            </template>

                                        </td>
                                        <td>{{item.roomNm}} <br> <span style="font-size: 0.7rem; color: gray;">{{item.roomForm}}</span>
                                        </td>
                                        <td>{{item.stdNop}}명/{{item.maxNop}}명 <br>
                                            <template v-if="dateRange > 0">
                                                <select v-model="item.resvNop" @change="selectChange($event, item)" style="height: 25px !important; padding:0 5px; !important; margin-bottom: 5px; ">
                                                    <option v-for="(n, i) in item.maxNopNum" :value="n">{{n}}</option>
                                                </select>
                                            </template>
                                            <template v-else>
                                                <select disabled  style="height: 25px !important; padding:0 5px; !important;margin-bottom: 5px; ">
                                                    <option value="0">0</option>
                                                </select>
                                            </template>
                                        </td>
                                        <td class="num-right">
                                            <template v-if="item.isSale=='true'">
                                                <span style="text-decoration: line-through; color:red;">{{item.oldPrice | comma}}원</span>
                                                <br>
                                            </template>
                                            <span style="color:#1976d2; font-weight: bold;">{{item.totalPrice | comma}}원</span>
                                        </td>
                                    </tr>
                                </table>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <span style="color:black; font-weight: bold; font-size:1rem !important;">
                                        ※ 다른 상품 추가 필요시 '다음단계'에서 예약 추가를 선택하세요
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="hidden-sm-and-down">
                                    <ul style="" class="roundBtn calc-4">
                                        <li></li>
                                        <li class="roundBtn-blue-li">
                                            <a @click="revSelectBtnClick('1')">선택취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revSelectBtnClick('3')">다음단계</a>
                                        </li>
                                        <li></li>
                                    </ul>
                                </v-col>
                                <v-col cols="12" class="hidden-md-and-up">
                                    <ul style="width:100%; margin:0 auto !important;" class="roundBtn calc-2">
                                        <li class="roundBtn-blue-li">
                                            <a @click="revSelectBtnClick('1')">선택취소</a>
                                        </li>
                                        <li class="roundBtn-red-li">
                                            <a @click="revSelectBtnClick('3')">다음단계</a>
                                        </li>
                                    </ul>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    export default {
        name: 'ReservationSearch',
        components: {},
        data: () => ({
            dialog: false,
            loading: true,
            searchGubun: "R",
            dateRange: 0,
            dates: {startDate: "", startDow: "", endDate: "", endDow: "", dateRange: ""},
            items: ['객실', '캠핑장A', '캠핑장B', '캠핑장C', '캠핑장D'],
            isSearch: true,
            resvSelectList: [],
            dateRangeText: "",
            resvConfirmList: "",


        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            this.dates.startDate = this.$getParameterByName('std');
            this.dates.endDate = this.$getParameterByName('edd');

            this.resvConfirmList = JSON.parse(JSON.stringify(this.$store.state.reservation.resvConfirmList));

            this.dateRangeText = this.dates.startDate + " ~ " + this.dates.endDate;

            if(this.dates.startDate === undefined || this.dates.startDate.length < 5){
                alert("잘못된 접근 방법 입니다.");
                window.history.back();

            }

            this.$nextTick(function () {

                this.clickSearchGubun('R');

            })

        },

        methods:
            {
                preventNav(event) {
                    event.preventDefault();
                    event.returnValue = ""
                },
                getDates() {

                    let dateRange = this.dateRangeText;
                    let dates = dateRange.split("~");

                    if(dates[0] == ''){

                        if(this.dialog){
                            setTimeout(() => {
                                this.dialog = false;

                                alert("날짜를 선택해 주세요.");

                            }, 1000);
                        }
                        return;
                    }

                    this.dates.startDate = dates[0].trim();
                    let std = new Date(dates[0].trim());
                    this.dates.endDate = dates[1].trim();
                    let end = new Date(dates[1].trim());
                    this.dateRange = Math.ceil((end.getTime() - std.getTime()) / (1000 * 3600 * 24));
                    let week = ['일', '월', '화', '수', '목', '금', '토'];

                    this.dates.startDow = week[new Date(this.dates.startDate).getDay()];
                    this.dates.endDow = week[new Date(this.dates.endDate).getDay()];

                    this.dates.dateRange = this.dateRange + "박 / " + (this.dateRange + 1) + "일";
                    //
                    if(this.dates.startDate === this.dates.endDate && this.dates.startDate.length > 0){
                        alert("날짜를 2일 이상 선택해 주세요");
                        this.init();
                    }

                },

                btnClick(val) {
                    this.dialog = true;

                    let _this = this;
                    this.getDates();

                    let formdata = {
                        "resvStartDate": this.dates.startDate,
                        "resvEndDate": this.dates.endDate,
                        "resvDayRage": this.dateRange,
                        "roomGubun": val,
                        "useYn": 'Y',
                        "delYn": 'N',

                    };

                    // 예약목록 가져오기
                    return this.$store.dispatch("reservation/getRoomKate", formdata)
                        .then((resp) => {
                            setTimeout(() => {

                                _this.resvSelectList = JSON.parse(JSON.stringify(resp.message[0].roomList));

                                _this.resvSelectList.forEach(function (e) {
                                    e['isSelect'] = false;
                                });

                                _this.resvSelectList.forEach(function (e, index) {

                                    _this.resvConfirmList.forEach(function (f) {
                                        if(f.roomId === e.roomId){

                                            e['isSelect'] = true;
                                            e['isCheck'] = true;
                                        }


                                    });

                                });

                                this.isSearch = true;
                            }, 1000);

                            setTimeout(() => {
                                this.dialog = false;
                            }, 1300);
                        })
                        .catch((err) => {
                        })


                },
                selectChange(event, item) {
                    let stdNop = parseInt(item.stdNop);

                    if (event.target.value > stdNop) {
                        item.totalPrice = parseInt(item.price) + ((item.osrScg * (event.target.value - stdNop)) * parseInt(this.dateRange))

                    } else {
                        item.totalPrice = parseInt(item.price);
                    }

                },
                revSelectBtnClick(val) {

                    switch (val) {
                        case "1" : { //취소
                            window.history.back();
                            break;
                        }

                        case "3": { //다음단계
                            this.dialog = true;

                            let resvConfirmList = this.resvConfirmList;

                            for (let i = 0; i < this.resvSelectList.length; i++) {

                                if (this.resvSelectList[i].isCheck && !this.resvSelectList[i].isSelect) {

                                    //this.$store.state.reservation.resvConfirmList.push(this.$store.state.reservation.resvSelectList[i]);
                                    if (this.resvSelectList[i].isStop === 'true') {
                                        alert("선택한 기간은 휴업기간입니다. 다른날을 선택해 주세요.");
                                        this.dialog = false;
                                        return;
                                    }

                                    resvConfirmList.push(this.resvSelectList[i])

                                }
                            }

                            if (resvConfirmList.length < 1) {
                                alert("예약할 객실을 선택해 주세요.");
                                this.dialog = false;
                                return;
                            }

                            // 최종 저장
                            setTimeout(() => {
                                this.$store.commit("reservation/SAVE_RESV_CONFIRM", resvConfirmList);
                                //this.$router.push({path: "/reservation/paymentTmp"});
                                location.href = "/reservation/paymentTmp" + '?std=' + this.dates.startDate + '&edd=' + this.dates.endDate+'&isAdd=true';
                                //this.dialog = false;
                            }, 1300);


                            break;
                        }

                    }
                },
                clickSearchGubun(val) { // 상단메뉴 클릭

                    //$('#searchGubun > li > a').removeClass("active");
                    //$("#searchGubun_" + val).addClass("active");

                    $('.searchGubun > li > a').removeClass("active");
                    $(".searchGubun_" + val).addClass("active");

                    this.searchGubun = val;

                    this.btnClick(val);

                },
                // 초기화
                init(val) {

                    //$('#searchGubun > li > a').removeClass("active");
                    //$("#searchGubun_" + val).addClass("active");

                    $('.searchGubun > li > a').removeClass("active");
                    $(".searchGubun_" + val).addClass("active");

                    this.searchGubun = val;

                    let fromdata = {
                        roomGubun: this.searchGubun,
                        useYn: "Y"
                    };

                    return this.$store.dispatch("room/getRoomList", fromdata)
                        .then((resp) => {
                            setTimeout(() => {

                                resp.message.forEach(function (e) {
                                    e["totalPrice"] = 0;
                                    e["resvNop"] = Number(e.stdNop);
                                    e["maxNopNum"] = Number(e.maxNop);

                                });

                                this.resvSelectList = (resp.message);
                                this.isSearch = true;
                            }, 300)
                        })
                        .catch((err) => {
                        })

                }

            }
        ,
        mounted() {
            this.$nextTick(() => {


            })
        },
        computed: {},
        updated: function () {  // 페이지 전부 생성후 자동 실행

        }
    }
</script>

<style>
    .v-input--selection-controls{
        margin-top:3px !important;
    }

    .v-input__control{
        height: 30px !important;
    }


</style>
